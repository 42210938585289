.root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    vertical-align: top;
    border: unset;
}

.fullwidth {
    width: 100%;
}

.outlined {
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: lightgray;
}

.underlined {
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: lightgray;
}

.label {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: top left;
    -webkit-transform: translate(14px, -9px) scale(0.75);
    -moz-transform: translate(14px, -9px) scale(0.75);
    -ms-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
    z-index: 1;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; */
    /* transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; */
}

.value_container {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    /* border-radius: 4px; */
}

.value_text {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    /* height: 1.4375em; */
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 14px 15px;
    word-break: break-word;
}