/* ------------------------ Loading expand icon start ----------------------- */
.animatedExpandButton {
    /* Layout */
    box-sizing: border-box;
    height: 17px;
    width: 17px;
    margin-top: 2.5px;
    margin-inline-end: 8px;
    padding: 0;
    position: relative;
    float:left;

    /* Coloring */
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 6px;

    /* Haptic */
    cursor: pointer;
    user-select: none;

    /* Text */
    text-decoration: none;
    text-transform: none;
    line-height: 17px;
    font-size: 14px;

    /* Transformations */
    transform: scale(0.9411764705882353);

    /* Animation */
    transition: all 0.3s;
}
.animatedExpandButton:hover {
    border-color: #40a9ff;
}
.animatedExpandButton:hover::after, .animatedExpandButton:hover::before {
    background-color: #40a9ff;
}

.animatedExpandButton::after {
    content: ""; /* this is necessary in order to display anything */
    position: absolute;
    background-color: black;
    transition: transform 0.3s ease-out;
    box-sizing: border-box;

    top: 3px;
    bottom: 3px;
    inset-inline-start: 7px;
    width: 1px;

    transform: rotate(90deg);
}
.animatedExpandButton_collapsed::after {
    transform: rotate(0deg);
}

.animatedExpandButton::before {
    content: ""; /* this is necessary in order to display anything */
    position: absolute;
    background-color: black;
    transition: transform 0.3s ease-out;
    box-sizing: border-box;

    top: 7px;
    inset-inline-end: 3px;
    inset-inline-start: 3px;
    height: 1px;
}
.animatedExpandButton_collapsed::before {
    transform: rotate(-180deg);
}

.animatedExpandButton_placeholder {
    box-sizing: border-box;
    padding-left: calc(14px + 1px + 8px); /* empty space that is exaclty as wide as the icon would take up */
}
/* ------------------------- Loading expand icon end ------------------------ */


/* -------------------------- Task done icon start -------------------------- */
.taskDoneCircle {
    stroke-dasharray: 49px; /* the exact length of the line that is drawn */
    stroke-dashoffset: 49px;

    /* animation */
    animation-name: dash;
    animation-duration: 0.17s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.taskDoneCheck {
    stroke-dasharray: 19px; /* the exact length of the line that is drawn */
    stroke-dashoffset: 19px;

    /* animation */
    animation-name: dash;
    animation-delay: 0.21s;
    animation-duration: 0.12s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0px;
    }
}
/* --------------------------- Task done icon end --------------------------- */