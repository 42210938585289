/* Layout classes */
.drawerBackdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
}

.drawerContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1200;
}

.drawer {
  /* Position within parent */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  /* Inner layout */
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;

  /* Animation properties used for removing/adding the indent */
  transition: left 250ms ease-out;
}

.drawerHeader {
  padding: 16px;
  height: 69px; /* round about the height of the header navbar */
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.drawerContentScrollContainer {
  margin-left: 2.5vw;
  margin-top: 2.5vw;
}

.drawerContent {
  padding-left: 2.5vw;
  padding-top: 2.5vw;
}

.drawerActions {
  padding: 16px;
}


/* Animation classes */
.drawerAppear {
  transform: translateX(100%);
}

.drawerAppearActive {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}

.drawerAppearDone {
  transform: translateX(0%);
}

.drawerEnter {
  transform: translateX(100%);
}

.drawerEnterActive {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}

.drawerEnterDone {
  transform: translateX(0%);
}

.drawerExit {
  transform: translateX(0%);
}

.drawerExitActive {
  transform: translateX(100%);
  transition: all 250ms ease-in;
}

.drawerExitDone {
  transform: translateX(100%);
}

.reduceDrawerIndent {
  left: 33vw !important;
}

.removeDrawerIndent {
  left: 0 !important;
}