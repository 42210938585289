
.highlight {
  font-size: medium;
  /* break sentence */
  white-space: pre-wrap;
  font-family: Arial, Helvetica, sans-serif;
}

.highlight del {
  text-decoration: line-through;
  background-color: lightcoral;
}

.highlight ins {
  background-color: lightgreen;
}